import styled from '@mui/material/styles/styled';
import TextField from '@mui/material/TextField';

const UnderlineColorTextField = styled(
  TextField,
  { shouldForwardProp: (prop) => !['underlineColor', 'disabledColor'].includes(prop) },
)(({ underlineColor, disabledColor }) => ({
  '& .MuiInput-root': {
    '&::before, &::after, &:hover:before': {
      borderBottomColor: `${underlineColor} !important`,
    },
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: disabledColor,
  },
}));

export default UnderlineColorTextField;
