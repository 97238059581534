import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();
const AsyncControlledAutocompleteInput = ({
  id,
  value: controlledValue,
  onChange,
  label,
  loading,
  options,
  disabled,
}) => (
  <Stack direction="column">
    <Typography variant="formLabel">{label}:</Typography>
    <Autocomplete
      id={id}
      disabled={disabled}
      value={controlledValue}
      onChange={(e, newValue) => {
        if (typeof newValue === 'string') {
          onChange(newValue);
        } else if (newValue && newValue.inputValue) {
          onChange(newValue.inputValue);
        } else {
          onChange(newValue.value);
        }
      }}
      loading={loading}
      isOptionEqualToValue={(option, value) => option.value === value}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.label;
      }}
      options={options}
      filterOptions={(_options, params) => {
        const filtered = filter(_options, params);
        const { inputValue } = params;
        const isExisting = _options.some((option) => inputValue === option.label);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            label: inputValue,
          });
        }
        return filtered;
      }}
      sx={{ inputRoot: { p: 0 } }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            variant: 'form',
            sx: { p: '0px 65px 0px 0px !important' },
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  </Stack>
);

AsyncControlledAutocompleteInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    }),
  ),
  disabled: PropTypes.bool,
};

AsyncControlledAutocompleteInput.defaultProps = {
  value: null,
  options: [],
  disabled: false,
};

export default AsyncControlledAutocompleteInput;
