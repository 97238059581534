import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';

const AutocompleteInput = ({ options, value, onChange, label, multiple }) => {
  // Find the corresponding option objects for the given value
  const selectedOptions = (multiple && value)
    ? options?.filter((option) => value.includes(option.value))
    : options?.find((option) => option.value === value);

  return (
    <Autocomplete
      options={options}
      value={selectedOptions || (multiple ? [] : null)}
      onChange={(_, newValue) => {
        onChange(newValue);
      }}
      limitTags={1}
      renderInput={(params) => (
        <TextField {...params} label={label} InputLabelProps={{ sx: { fontWeight: 'bold' } }} />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      disableListWrap={false}
      disablePortal
      fullWidth
    />
  );
};

AutocompleteInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
};

AutocompleteInput.defaultProps = {
  multiple: false,
  value: null,
};

export default AutocompleteInput;
