import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const ControlledRadioGroupInput = ({ value, onChange, options, label, disabled }) => (
  <FormControl disabled={disabled}>
    <FormLabel>{label}</FormLabel>
    <RadioGroup row value={value} onChange={onChange}>
      {options.map(({ value: optionValue, label: optionLabel }) => (
        <FormControlLabel
          key={optionValue}
          value={optionValue}
          control={<Radio />}
          label={optionLabel}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

ControlledRadioGroupInput.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ControlledRadioGroupInput.defaultProps = {
  value: null,
  disabled: false,
};

export default ControlledRadioGroupInput;
