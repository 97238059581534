import React from 'react';
import Avatar from '@mui/material/Avatar';
import getCdnUrl from '../../helpers/get-cdn-url';

const SANTANDER_IMAGE = getCdnUrl(
  '/common_assets/assets/icons/santander_image.png',
);

const SantanderIcon = () => (
  <Avatar src={SANTANDER_IMAGE} sx={{ width: 17, height: 17 }} />
);

export default SantanderIcon;
