import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ValidationTextFieldInput } from '.';
import { useInputRut } from '../../hooks';

const RutInput = ({
  onChange,
  initialIdentifierValue,
  ...restProps
}) => {
  const { nationalIdentifier: rut, updateNationalIdentifier: updateRut } = useInputRut(
    initialIdentifierValue,
  );
  const onChangeInput = useCallback((event) => {
    const { value } = event.target;
    updateRut(value);
  }, []);
  useEffect(() => {
    onChange({ target: { name: restProps.name, value: rut.formatted } });
  }, [rut.formatted]);
  return (
    <ValidationTextFieldInput
      type="text"
      validationtype="rut"
      variant="standard"
      fullWidth
      value={rut.formatted}
      onChange={onChangeInput}
      {...restProps}
    />
  );
};

RutInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialIdentifierValue: PropTypes.string,
};

RutInput.defaultProps = {
  initialIdentifierValue: '',
};

export default RutInput;
