import Avatar from '@mui/material/Avatar';
import React from 'react';
import getCdnUrl from '../../helpers/get-cdn-url';

const FINGO_IMAGE_URL = getCdnUrl(
  '/common_assets/assets/icons/fingo_image.png',
);

const FingoIcon = () => (
  <Avatar
    src={FINGO_IMAGE_URL}
    sx={{ width: 17, height: 17, borderRadius: 0 }}
  />
);

export default FingoIcon;
