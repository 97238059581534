import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  MenuItem,
  ListItemText,
  Stack,
} from '@mui/material';
import FileDownload from '@mui/icons-material/FileDownload';
import useFetchPresignedUrl from '../../hooks/useFetchPresignedUrl';

const ClientDocumentsItem = ({ documentMaper, companyId, document }) => {
  const [fetchUrl] = useFetchPresignedUrl(document?.globalAppId);
  return (
    <MenuItem
      sx={{ height: 40, width: 210 }}
      value={documentMaper.id}
      divider={documentMaper.divider}
      onClick={fetchUrl}
      disabled={!document}
    >
      <Stack direction="row" spacing={1}>
        <Icon
          key={`documents-company-${companyId}-${documentMaper.key}-3`}
          fontSize="small"
          color="primary"
        >
          <FileDownload />
        </Icon>
        <ListItemText
          primary={documentMaper.name}
          secondary={document?.lastDate}
          key={`documents-company-${companyId}-${documentMaper.key}-2`}
        />
      </Stack>
    </MenuItem>
  );
};

ClientDocumentsItem.propTypes = {
  documentMaper: PropTypes.shape({
    key: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    download: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    mutation: PropTypes.object,
    divider: PropTypes.bool.isRequired,
  }).isRequired,
  companyId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastDate: PropTypes.string.isRequired,
    lastFile: PropTypes.string.isRequired,
    globalAppId: PropTypes.string.isRequired,
  }),
};

ClientDocumentsItem.defaultProps = {
  document: null,
};

export default ClientDocumentsItem;
