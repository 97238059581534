import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  ListItemText,
  Icon,
  Stack,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import CachedIcon from '@mui/icons-material/Cached';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const ClientCTEandTGRDocumentsItem = ({ documentMaper, companyId, document }) => {
  const [fetchDocument, { error, data }] = useMutation(
    documentMaper.mutation,
    {
      variables: { companyId },
      notifyOnNetworkStatusChange: true },
  );
  const fetchColor = useMemo(() => {
    if (error) return 'warning';
    if (data) return 'success';
    return 'primary';
  }, [data, error]);
  const fetchLoadingButton = useMemo(() => {
    if (data) return (<CheckCircleIcon fontSize="small" />);
    if (error || !document) return (<CancelIcon fontSize="small" />);
    return (<CachedIcon fontSize="small" />);
  }, [data, error, document]);
  return (
    <MenuItem
      sx={{ height: 40, width: 210 }}
      value={documentMaper.id}
      divider={documentMaper.divider}
      onClick={fetchDocument}
    >
      <Stack direction="row" spacing={1}>
        <Icon
          key={`documents-company-${companyId}-${documentMaper.key}-3`}
          fontSize="small"
          color={fetchColor}
        >
          {fetchLoadingButton}
        </Icon>
        <ListItemText
          primary={documentMaper.name}
          secondary={document?.lastDate}
          key={`documents-company-${companyId}-${documentMaper.key}-2`}
        />

      </Stack>
    </MenuItem>
  );
};

ClientCTEandTGRDocumentsItem.propTypes = {
  documentMaper: PropTypes.shape({
    key: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    download: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    mutation: PropTypes.object,
    divider: PropTypes.bool.isRequired,
  }).isRequired,
  companyId: PropTypes.string.isRequired,
  document: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastDate: PropTypes.string.isRequired,
    lastFile: PropTypes.string.isRequired,
    globalAppId: PropTypes.string.isRequired,
  }),
};

ClientCTEandTGRDocumentsItem.defaultProps = {
  document: null,
};

export default ClientCTEandTGRDocumentsItem;
