import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SatIconImage } from '../../assets/icons/sat_icon.svg';

const SatIcon = ({ sx, ...props }) => (
  <SvgIcon {...props} alt="sat-icon">
    <SatIconImage fill={sx.color} />
  </SvgIcon>
);

SatIcon.propTypes = {
  sx: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
};

export default SatIcon;
