import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

const PasswordInput = ({ Component, iconColor, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePassword = useCallback(() => setShowPassword((prev) => !prev), []);

  return (
    <Component
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        // eslint-disable-next-line react/destructuring-assignment, react/prop-types
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePassword}
              onMouseDown={togglePassword}
              color={iconColor}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.object,
  iconColor: PropTypes.string,
};
PasswordInput.defaultProps = {
  Component: TextField,
  iconColor: 'inherit',
};

export default PasswordInput;
