import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const ControlledNumberInput = ({ value, onChange, label }) => (
  <Stack direction="column">
    <Typography variant="formLabel">{label}:</Typography>
    <TextField
      value={value || ''}
      type="number"
      onKeyDown={(e) => {
        if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
          e.preventDefault();
        }
      }}
      onChange={onChange}
      InputProps={{ variant: 'form' }}
      variant="outlined"
    />
  </Stack>
);

ControlledNumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

ControlledNumberInput.defaultProps = {
  value: null,
};

export default ControlledNumberInput;
