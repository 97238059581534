import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as SiiIconImage } from '../../assets/icons/sii_icon.svg';

const SiiIcon = ({ sx, ...props }) => (
  <SvgIcon {...props} alt="sii-icon">
    <SiiIconImage fill={sx.color} />
  </SvgIcon>
);

SiiIcon.propTypes = {
  sx: PropTypes.shape({
    color: PropTypes.string,
  }).isRequired,
};

export default SiiIcon;
